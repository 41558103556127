@import url('src/styles/variables.scss');

.body-container {
  width: 100vw;
  height: 100vh;
  background-image: url('../../assets/images/authen_bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.authen-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo-container {
  width: 150px;
  height: 104px;
  margin-bottom: 10px;
}
.authen-form-container {
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12);
  border-radius: 16px;
  padding: 15px 20px;
}
