h1,
h2,
h3,
h4,
h5 {
  margin-block-start: 0 !important;
}
.ant-card {
  .ant-card-head {
    border-bottom: none !important;
  }
}
