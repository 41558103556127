@import url('../../styles/variables.scss');

.sider-container {
  box-shadow: 0 0.125rem 0.375rem 0 rgba(161, 172, 184, 0.12);
  overflow-y: auto;
}
.header-container {
  opacity: 0.9;
  box-shadow: 0 0 0.375rem 0.25rem rgba(161, 172, 184, 0.15);
  margin-top: 10px;
  border-radius: 8px;
  padding: 0 15px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.app-content-container {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 15px 20px;
}
@media screen and (max-width: 992px) {
  .content-container {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (max-width: 1200px) {
  .header-container {
    opacity: 1;
    opacity: 0.95;
    box-shadow: 0 0 0.375rem 0.25rem rgba(161, 172, 184, 0.15);
    margin-top: 0;
    border-radius: 0;
    padding: 0 15px !important;
    margin-bottom: 15px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 100;
  }
  .app-content-container {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
