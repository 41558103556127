
.building-form-container {
  border-radius: 16px;
  background-color: white;
  border-width: 1px;
  border-color: #eeeeee;
  width: 100%;
  margin-top: 20px;
  padding: 15px 20px 15px 20px;
}
