.profile-screen-container {
  width: 100%;
  padding-top: 20px;
  .section-container {
    padding: 15px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12);
    width: 100%;
  }
  .profile-content-container {
    margin-top: 15px;
  }
  .form-container {
    padding-top: 15px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
